<template>
	<e-select
		:options="currencies"
		track-by="currency_name"
		v-model="internalValue"
		:allow-empty="false"
		:custom-label="formatLabel"
	>
	</e-select>
</template>

<script>
import Accounting from '@/mixins/Accounting'

export default {
	name: 'CurrencyInput',
	mixins: [Accounting],
	props: {
		value: {
			type: String,
			default: () => ('EUR')
		}
	},
	data: () => ({
		currencies: []
	}),
	computed: {
		internalValue: {
			get() {
				return this.currencies.find((cur) => (cur.currency_code === this.value))
			},
			set(val) {
				this.$emit('input', val.currency_code)
			}
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.currencies = await this.loadCurrencies()
		},
		formatLabel(currency) {
			return `${currency.currency_name} (${currency.currency_symbol})`
		}
	}
}
</script>
